/* Override default */
.Draftail-Editor {
  border: none;
  border-radius: 0;
}

/* Override Default */
.Draftail-Toolbar {
  border-radius: 0;
}

.public-DraftEditor-content {
  overflow: auto
}

.Draftail-Editor .DraftEditor-root {
  line-height: 1;
  font-size: 14px;
}

.Draftail-Editor--focus .Draftail-Toolbar {
  top: 0;
  z-index: 4;
  position: relative
}
