.DateRangePickerInput {
  border-radius: 5px;
  background-color: transparent;
  border-color: #bcbebf;
}

.DateInput {
  padding: 14px 10px;
  border-radius: 5px;
  background-color: transparent;
}

.DateInput_input {
  padding:0;
  background-color: transparent;
}

.DateInput_input::placeholder {
  font-weight: 100;
}